<template>
    <div class="container">
        <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token" />
    </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
    name: "springPoster",
    components: {
        Poster
    },
    data() {
        return {
            token: this.$route.query.token,
            posterList: [
                {
                    backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/shareChess/20220714103611718.png',
                    tempBgImage: null,
                    miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/shareChess/20220714103627368.png',
                    textColor: '#ffffff',
                    tempPoster: '',
                    textPoint: [432, 1203],
                    wxCode: { width: 188, point: [58, 1130] }
                },
                {
                    backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/shareChess/20220714103718590.png',
                    tempBgImage: null,
                    miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220714103750475.png',
                    textColor: '#ffffff',
                    tempPoster: '',
                    textPoint: [432, 1203],
                    wxCode: { width: 188, point: [58, 1130] }
                },
                {
                    backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220714103815910.png',
                    tempBgImage: null,
                    miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220714103832062.png',
                    textColor: '#ffffff',
                    tempPoster: '',
                    textPoint: [432, 1203],
                    wxCode: { width: 188, point: [58, 1130] }
                }
            ],
            pagePath: this.baseHost + '/',
            moduleKey: ''
        }
    },
    created() {
        this.moduleKey = this.token ? 'cb97de0e-6003-41b1-9c93-7b778dd67efb' : 'gzhMW20210208'
    }
}
</script>

<style scoped>
</style>